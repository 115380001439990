<template>
  <page-view ref="pagediv" :title="title" left-arrow>
    <h2 class="inline-title">工单信息</h2>
    <van-cell-group>
      <van-cell title="业务编号" :value="gzdxx.ywbh"/>
      <van-cell title="业务类别" :value="gzdxx.ywlbmc"/>
      <van-cell title="当前环节" :value="gzdxx.gnmc" />
      <van-cell title="申请时间" :value="gzdxx.cjsj" />
      <van-cell title="备注说明" :value="gzdxx.jypz" />
    </van-cell-group>

    <h2 class="inline-title">处理进度</h2>
    <van-steps direction="vertical" :active="processList.length - 1">
      <van-step  v-for="(item,idx) in processList" :key="idx" class="lchjItem">
        <div class="hjmc">{{item.gnmc}}</div>
        <div v-if="item.spyjList.length" class="lchjItem__content">
          <div v-for="(task, index) in item.spyjList" :key="index" style="margin-bottom: 10px;">
            <p>
              <span class="lchjItem__clr">{{task.spr}}&nbsp;&nbsp;</span>
              <span style="font-weight: bold;">{{task.spyj ? '已审批。': '审批中。'}}</span>
            </p>
            <div v-if="task.spyj">
              意见：{{task.SPYJMC}}&nbsp;&nbsp;
              <span v-if="task.yjsm">【{{task.yjsm}}】</span>  
            </div>
          </div>
        </div>
        <div v-else class="lchjItem__content">
          <span style="font-weight: bold;">{{item.endTime ? '已完成' : '处理中'}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span class="lchjItem__clr">{{item.sdrmc}}&nbsp;&nbsp;</span>
          <span class="lchjItem__clsj">{{item.endTime}}&nbsp;&nbsp;</span>
          <span>{{item.endTime ? '处理完毕。' : '开始处理。'}}</span>
        </div>
      </van-step>
    </van-steps>
  </page-view>
</template>

<script>
  import { mapGetters } from 'vuex';

  import { Uploader, Steps, Step, Card } from 'vant';
  import Loading from '@/components/LoadingLayout/loading.vue';

  import { gzdxxService_getGzdLcgzb } from '@/api/psdmsgdgl/service/GzdxxServiceAPI';

  const PAGE_SIZE = 5;
  export default {
    name: 'gdxx',
    components: {
      Loading,
      [Uploader.name]: Uploader,
      [Card.name]: Card,
      [Steps.name]: Steps,
      [Step.name]: Step,
    },
    data() {
      return {
        title: '工单信息',
        qdrq: null,
        ywbh: null,
        gzdxx: {
          qdrq: '',
          ywbh: ''
        },
        processList: [],
        fjList: []
      }
    },
    computed: {
      ...mapGetters(['jgbm','dybm','czyh','dlzh']),
    },
    methods: {
      fetchProcessData() {
				if (!this.qdrq || !this.ywbh) {
					return false;
				}
				this.processList = [];
        let loading = this.$toast.loading();
				gzdxxService_getGzdLcgzb(this.qdrq, this.ywbh).then(res => {
          loading.clear();
					if (res.content.status == 200) {
						this.processList = res.content.data;
					}
				}).catch(err => {
          console.log(err);
          loading.clear();
        });
			},
    },
    mounted() {
      let {qdrq, ywbh} = this.$route.query;
      this.qdrq = qdrq;
      this.ywbh = ywbh;

      this.gzdxx = this.$route.query;
      this.fetchProcessData()
    },
  }
</script>

<style lang="scss" scoped>
  .inline-title {
    color: rgba(69, 90, 100, 0.6);
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
  }

  .lchjItem /deep/ {
    display: flex;
    color: #333;
    .hjmc {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  .lchjItem__content /deep/ {
    span {
      color: #333;
      font-size: 14px;
      line-height: 1.4;
    }

    .lchjItem__clr {
      color: #00c4ff;
    }

    .lchjItem__clsj {
      color: #888;
    }
  }
</style>